import classnames from 'classnames';
import React, { Dispatch, SetStateAction } from 'react';

import ListLoadingSpinner from '../animation/ListLoadingSpinner';

export type AdminCardProps = {
    cardClassName?: string;
    className?: string;
    superTitle?: string;
    superTitleClassName?: string;
    title?: string;
    titleClassName?: string;
    trailingComponentClassName?: string;
    leadingComponent?: JSX.Element;
    trailingComponent?: JSX.Element;
    onIconClick?: () => void;
    twoColumnsOnLg?: boolean;
    children?;
    allowOverflow?: boolean;
    fullHeight?: boolean;
    customWidth?: string;
    isLoading?: boolean;
    setHovered?: Dispatch<SetStateAction<boolean>>;
    leftMarginContent?: JSX.Element;
};

export default function AdminCard(props: AdminCardProps) {
    const {
        cardClassName,
        className,
        superTitle,
        superTitleClassName,
        title,
        titleClassName,
        leadingComponent,
        trailingComponent,
        trailingComponentClassName,
        twoColumnsOnLg,
        children,
        allowOverflow,
        fullHeight,
        isLoading,
        customWidth,
        setHovered,
        leftMarginContent
    } = props;

    return (
        <div
            className={classnames(
                cardClassName,
                'p-3 md:p-16 rounded-8 bg-darkBlue bg-opacity-20 text-ellipsis',
                customWidth,
                { 'w-full': !customWidth },
                { 'h-full': fullHeight },
                { 'lg:w-1/2': twoColumnsOnLg },
                { 'overflow-hidden': !allowOverflow }
            )}
            onMouseOver={() => {
                setHovered && setHovered(true);
            }}
            onMouseLeave={() => {
                setHovered && setHovered(false);
            }}>
            {isLoading && <ListLoadingSpinner />}

            {!isLoading && superTitle && (
                <div className={classnames('text-cyan uppercase text-overline-16', superTitleClassName)}>
                    {superTitle}
                </div>
            )}

            {!isLoading && (
                <div className={classnames(className, 'space-y-16 text-ellipsis w-full')}>
                    {(leadingComponent || title || trailingComponent) && (
                        <div
                            className={classnames(trailingComponentClassName, {
                                'flex flex-col lg:flex-row space-y-8 lg:space-y-0 justify-between w-full':
                                    trailingComponent
                            })}>
                            <div className="flex flex-row space-x-16">
                                {leadingComponent}
                                {title && <h2 className={classnames('text-body-bold-16', titleClassName)}>{title}</h2>}
                            </div>
                            {trailingComponent}
                        </div>
                    )}
                    {leftMarginContent ? (
                        <div className="flex flex-col lg:flex-row w-full space-y-24 lg:space-y-0">
                            <div className="flex-none w-full lg:w-240 border-r-0 lg:border-r-1 border-b-1 lg:border-b-0 border-white-20 mt-10 pr-12 pb-24 lg:pb-0">
                                {leftMarginContent}
                            </div>
                            <div className="flex w-full lg:w-0 flex-auto">{children}</div>
                        </div>
                    ) : (
                        children
                    )}
                </div>
            )}
        </div>
    );
}
