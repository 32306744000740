// TODO: Check if there is another solution for 'const plugin = require()'
// eslint-disable-next-line @typescript-eslint/no-var-requires
const plugin = require('tailwindcss/plugin');

module.exports = {
    mode: 'jit',
    content: [
        './pages/**/*.{js,ts,jsx,tsx}',
        './components/**/*.{js,ts,jsx,tsx}',
        'cineamo-frontend-lib/src/**/*.{js,ts,jsx,tsx}',
        '../../packages/cineamo-frontend-lib/src/**/*.{js,ts,jsx,tsx}'
    ],
    purge: false,
    darkMode: 'media', // or 'media' or 'class'
    theme: {
        extend: {
            width: {
                50: '5.0rem',
                56: '5.6rem',
                60: '6.0rem',
                65: '6.5rem',
                72: '7.2rem',
                78: '7.8rem',
                94: '9.4rem',
                125: '12.5rem',
                140: '14rem',
                160: '16rem',
                170: '17rem',
                180: '18rem',
                200: '20rem',
                215: '21.5rem',
                240: '24rem',
                250: '25rem',
                260: '26rem',
                300: '30rem',
                400: '40rem',
                500: '50rem',
                700: '70rem',
                icon: '4rem',
                'icon-lg': '6rem',
                'list-backdrop': '10.7rem',
                'mobile-modal': '98%',
                'two-column-image': '80%'
            },
            height: {
                icon: '4rem',
                'icon-lg': '6rem',
                48: '4.8rem',
                50: '5.0rem',
                56: '5.6rem',
                60: '6.0rem',
                72: '7.2rem',
                114: '11.4rem',
                140: '14rem',
                160: '16rem',
                180: '18rem',
                200: '20rem',
                300: '30rem',
                240: '24rem',
                250: '25rem',
                400: '40rem',
                500: '50rem',
                640: '64rem',
                700: '70rem',
                800: '80rem',
                864: '80.64rem',
                900: '90rem',
                'mobile-modal': '98%',
                navbar: '6rem',
                'list-backdrop': '16rem'
            },
            minWidth: {
                icon: '4rem',
                'icon-lg': '6rem',
                32: '3.2rem',
                '46': '4.6rem',
                72: '7.2rem',
                82: '8.2rem',
                124: '12.4rem',
                140: '14rem',
                148: '14.8rem',
                200: '20rem',
                215: '21.5rem',
                300: '30rem',
                500: '50rem',
                '1/3': '33.333333%',
                '2/5': '40%',
                '3/5': '60%',
                '2/3': '66.666666%'
            },
            maxWidth: {
                icon: '4rem',
                'icon-lg': '6rem',
                100: '10rem',
                140: '14rem',
                '2/5': '40%',
                '3/5': '60%',
                '2/3': '66.666666%'
            },
            minHeight: {
                icon: '4rem',
                'icon-lg': '6rem',
                24: '2.4rem',
                32: '3.2rem',
                48: '4.8rem',
                72: '7.2rem',
                80: '8rem',
                120: '1.2rem',
                '3/5': '60%',
                '2/3': '66.666666%'
            },
            maxHeight: {
                icon: '4rem',
                'icon-lg': '6rem',
                180: '18.0rem',
                480: '48.0rem'
            },
            spacing: {
                'screen-inset': '0.8rem',
                'screen-inset-md': '1.6rem',
                'screen-inset-lg': '4.8rem',
                1: '0.1rem',
                2: '0.2rem',
                4: '0.4rem',
                5: '0.5rem',
                6: '0.6rem',
                7: '0.7rem',
                8: '0.8rem',
                10: '1.0rem',
                12: '1.2rem',
                14: '1.4rem',
                16: '1.6rem',
                18: '1.8rem',
                20: '2.0rem',
                22: '2.2rem',
                24: '2.4rem',
                28: '2.8rem',
                32: '3.2rem',
                36: '3.6rem',
                38: '3.8rem',
                40: '4.0rem',
                44: '4.4rem',
                48: '4.8rem',
                50: '5.0rem',
                52: '5.2rem',
                57: '5.7rem',
                60: '6.0rem',
                64: '6.4rem',
                65: '6.5rem',
                72: '7.2rem',
                84: '8.4rem',
                96: '9.6rem',
                100: '10rem',
                108: '10.8rem',
                120: '12rem',
                148: '14.8rem',
                160: '32rem',
                '1/10': '10%',
                '2/10': '20%'
            },
            lineHeight: {
                22: '2.2rem',
                24: '2.4rem',
                28: '2.8rem',
                38: '3.8rem',
                48: '4.8rem'
            },
            borderWidth: {
                1: '0.1rem'
            },
            borderSpacing: {
                10: '1.0rem'
            },
            divideWidth: {
                0.5: '0.05rem'
            },
            opacity: {
                15: '0.15',
                65: '0.65'
            },
            transitionProperty: {
                width: 'width',
                height: 'height'
            },
            keyframes: {
                progressBar: {
                    '0%': { transform: 'scaleX(0)' },
                    '100%': { transform: 'scaleX(1)' }
                },
                pingWithPauseKeyframes: {
                    '0%, 70%': {
                        transform: 'scale(1)',
                        opacity: 1
                    },
                    '90%, 100%': {
                        transform: 'scale(2)',
                        opacity: 0
                    }
                }
            },
            animation: {
                progressBar2500: 'progressBar 2500ms',
                bounce200: 'bounce 1s infinite 200ms',
                bounce400: 'bounce 1s infinite 400ms',
                pingWithPause: 'pingWithPauseKeyframes 3s cubic-bezier(0, 0, 0.2, 1) infinite'
            },
            zIndex: {
                'navigation-dropdown': '-1',
                'menu-button': '150',
                navigation: '1000',
                'modal-view': '9000',
                'loading-overlay': '9999'
            },
            boxShadow: {
                cyan: '0 0 10px 0 #00FFDC',
                'cyan-transparent': '0 0 4px 0 #00FFDC80',
                'darkBlue-20': '0 0 6px 0 #00002332'
            }
        },

        letterSpacing: {
            'small': '0.02em',
            'regular': '0.035em',
            'medium': '0.05em',
            'wide': '0.15em'
        },

        fontFamily: {
            sans: ['Montserrat', 'Montserrat, Arial, sans-serif']
        },

        fontSize: {
            'headline-56': '5.6rem',
            'headline-42': '4.2rem',
            'headline-32': '3.2rem',
            'headline-24': '2.4rem',
            'headline-20': '2.0rem',
            'headline-18': '1.8rem',

            'body-20': '2.0rem',
            'body-16': '1.6rem',
            'body-14': '1.4rem',
            'body-12': '1.2rem',
            'body-11': '1.1rem',
            'body-10': '1.0rem',
            'body-56': '5.6rem',

            'body-bold-20': '2.0rem',
            'body-bold-16': '1.6rem',
            'body-bold-14': '1.4rem',
            'body-bold-12': '1.2rem',
            'body-bold-10': '1.0rem',

            'overline-16': '1.6rem',
            'overline-14': '1.4rem',
            'overline-12': '1.2rem',
            'overline-10': '1.0rem',

            'button-16': '1.6rem',
            'button-14': '1.4rem',
            'button-12': '1.2rem',
            'button-10': '1.0rem',

            'input-labels-12': '1.2rem'

            // '2xl': '3.2rem',
        },

        borderRadius: {
            0: '0rem',
            2: '0.2rem',
            3: '0.3rem',
            4: '0.4rem',
            8: '0.8rem',
            switch: '1.2rem',
            full: '100%'
        },

        colors: {
            transparent: 'transparent',
            current: 'currentColor',
            cyan: {
                DEFAULT: '#00FFDC',
                light: '#3fffe4',
                middle: '#13E2C6',
                'on-white-bg': '#13E2C6',
                dark: '#00AAA0',
                transparent: '#00FFDC80',
                'transparent-20': 'rgba(0,255,220,0.2)',
                link: '#79F9E7'
            },
            darkBlue: {
                DEFAULT: '#000023',
                light: '#1A0A49',
                lightest: '#000063',
                transparent: '#00002380',
                supertransparent: '#00002333'
            },
            blue: {
                DEFAULT: '#0071F7',
                light: '#6BAFFF',
                lightest: '#C5DEFC',
                transparent: '#0071F780',
                'transparent-20': '#0071F754',
                supertransparent: '#0071F733'
            },
            purple: {
                DEFAULT: '#9B51E0',
                light: '#DD82FF',
                lightest: '#EAC4F9',
                transparent: '#9B51E080',
                'transparent-20': '#9B51E054'
            },
            green: {
                DEFAULT: '#40E587',
                light: '#79E9A8',
                lightest: '#B6F8D1'
            },
            berry: {
                DEFAULT: '#FC0073',
                light: '#FF4387',
                lightest: '#FC94C3'
            },
            black: {
                light: '#222222',
                DEFAULT: '#000000'
            },
            white: {
                DEFAULT: '#F2F2F2',
                20: '#F2F2F233',
                50: '#F2F2F280'
            },
            gray: {
                DEFAULT: '#CCCCD4',
                light: '#DBDBE2',
                middle: '#B3B3BD',
                transparent: '#F2F2F280'
            },
            'movie-pilot': {
                orange: '#F4645A'
            },
            red: {
                DEFAULT: '#FF3933'
            },
            orange: {
                DEFAULT: '#FFA620',
                dark: '#E06019',
                light: '#FFDD5C',
                gradient: '#ED820E'
            },
            yellow: {
                DEFAULT: '#FFDE2E'
            },
            violetGrey: {
                DEFAULT: '#887D9A'
            }
        },

        scale: {
            0: '0',
            '01': '0.001',
            1: '0.01',
            25: '.25',
            50: '.5',
            75: '.75',
            90: '.9',
            95: '.95',
            100: '1',
            101: '1.01',
            105: '1.05',
            110: '1.1',
            125: '1.25',
            150: '1.5',
            200: '2'
        }
    },
    variants: {
        padding: ['responsive', 'important'],
        extend: {
            margin: ['first', 'last', 'hover', 'group-hover'],
            padding: ['group-hover'],
            backgroundColor: ['active'],
            backgroundOpacity: ['active'],
            backdropFilter: ['hover'],
            backdropBlur: ['hover'],
            borderStyle: ['first', 'last'],
            borderColor: ['first', 'last'],
            borderRadius: ['first', 'last'],
            boxShadow: ['active'],
            translate: ['active'],
            textColor: ['active'],
            opacity: ['active', 'hover'],
            width: ['hover', 'group-hover'],
            height: ['hover'],
            maxWidth: ['group-hover'],
            maxHeight: ['group-hover'],
            scale: ['group-hover'],
            fill: ['hover', 'focus'],
            zIndex: ['hover', 'group-hover'],
            display: ['group-hover'],
            inset: ['group-hover'],
            rotate: ['group-hover'],
            visibility: ['hover', 'group-hover'],
            fontFamily: ['hover']
        }
    },
    corePlugins: {
        container: false
    },
    plugins: [
        require('@tailwindcss/forms'),
        require('tailwind-gradient-mask-image'),
        require('@tailwindcss/aspect-ratio'),
        ({ addComponents, theme }) => {
            const containerBreakpoints = {
                '.container': {
                    paddingInline: theme('spacing.4'),
                    // maxWidth: theme('screens.lg'),
                    paddingRight: '1.6rem',
                    width: '100%',

                    // Breakpoints
                    '@screen sm': {
                        // maxWidth: theme('screens.sm'),
                        paddingLeft: '3.8rem',
                        paddingRight: '3.8rem'
                    },
                    '@screen md': {
                        // maxWidth: theme('screens.md'),
                        paddingLeft: '1.0rem',
                        paddingRight: '1.0rem'
                    },
                    '@screen lg': {
                        // maxWidth: theme('screens.lg'),
                        paddingLeft: '1.0rem',
                        paddingRight: '1.0rem'
                    },
                    '@screen xl': {
                        // maxWidth: theme('screens.xl'),
                        paddingLeft: '1.0rem',
                        paddingRight: '1.0rem'
                    },
                    '@screen 2xl': {
                        maxWidth: theme('screens.2xl'),
                        paddingLeft: '1.0rem',
                        paddingRight: '1.0rem'
                    }
                }
            };
            const screenPaddingBreakpoints = {
                '.screen-padding': {
                    paddingLeft: '1.4rem',
                    paddingRight: '1.4rem',

                    '@screen sm': {
                        paddingLeft: '3.8rem',
                        paddingRight: '3.8rem'
                    },
                    '@screen md': {
                        paddingLeft: '3.8rem',
                        paddingRight: '3.8rem'
                    },
                    '@screen lg': {
                        paddingLeft: '4.8rem',
                        paddingRight: '4.8rem'
                    },
                    '@screen xl': {
                        paddingLeft: '4.8rem',
                        paddingRight: '4.8rem'
                    },
                    '@screen 2xl': {
                        paddingLeft: '4.8rem',
                        paddingRight: '4.8rem'
                    }
                }
            };
            const modalBreakpoints = {
                '.modal-window': {
                    paddingInline: theme('spacing.4'),
                    maxWidth: '100%',
                    paddingLeft: '1.6rem',
                    paddingRight: '1.6rem',
                    width: '100%',

                    // Breakpoints
                    '@screen sm': {
                        maxWidth: '550px',
                        paddingLeft: '2.8rem',
                        paddingRight: '2.8rem'
                    },
                    '@screen md': {
                        maxWidth: '600px',
                        paddingLeft: '3.8rem',
                        paddingRight: '3.8rem'
                    },
                    '@screen lg': {
                        maxWidth: '800px',
                        paddingLeft: '4.8rem',
                        paddingRight: '4.8rem'
                    }
                }
            };

            addComponents([containerBreakpoints, screenPaddingBreakpoints, modalBreakpoints]);
        },

        plugin(({ addVariant }) => {
            addVariant('important', ({ container }) => {
                container.walkRules((rule) => {
                    rule.selector = `.\\!${rule.selector.slice(1)}`;
                    rule.walkDecls((decl) => {
                        decl.important = true;
                    });
                });
            });
        })
    ]
};
